<template>
  <section>
    <div class="flex flex-no-wrap">
      <Nav />

      <section class="enterprise-setup h-100 p-3 pb-5">
        <div class="container mb-2">
          <div class="d-flex align-items-center justify-content-center h-100 mt-3 mb-3 pt-2 pb-2">
            <div class="d-flex align-items-center me-auto">
              <div class="flex-shrink-0">
                <img
                  src="@/assets/images/logo.png"
                  class="logo"
                  alt="Relatus"
                >
              </div>
              <div class="flex-grow-1 ms-3">
                <h2><span class="font-normal">Your</span> Relatus Profile</h2>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <r-button
                variant="primary"
                round
                outline
                class="ml-auto"
              >
                Save Changes
              </r-button>
            </div>
          </div>
        </div>

        <div class="container container-card h-100 p-2">
          <div class="row">
            <div class="col-12 text-start">
              <div class="d-flex align-items-center border-light-gray ps-4 pe-4" style="border: 1px solid rgba(3, 29, 90, 0.2);box-sizing: border-box;border-radius: 12px;margin: 20px;padding: 15px;">
                <div class="flex-shrink-0">
                  <img
                    src="@/assets/images/icons/undraw_profile_edit.svg"
                    alt="Enterprise Setup"
                    style="max-height: 70px;"
                  >
                </div>
                <div class="flex-grow-1 ms-3">
                  <h5 class="text align-self-center me-auto fw-light pb-0">
                    Enhance your Existing Profile (Personal Information)
                  </h5>
                  <p class="text align-self-center text-start" style="color: #999799;">
                    You can confirm or edit the information we’ve collected about you here. This information belongs to you and you alone.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container container-card h-100 p-2">
          <div class="row">
            <div class="col-12 text-start">
              <h5 class="text align-self-center me-auto fw-light">
                The Basics
              </h5>
            </div>
          </div>
        </div>

        <div class="container container-card h-100 p-2">
          <div class="row">
            <div class="col-12 text-start">
              <h5 class="text align-self-center me-auto fw-light" style="border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);">
                About Me
              </h5>

              <div class="mt-3 mb-3">
                <span class="mr-2" style="color: #8B91A0;">5 WordsThat Best Describe Me</span>
                <span class="tag">Extrovert</span>
                <span class="tag">Risk Taker</span>
                <span class="tag">Passionate</span>
                <span class="tag">Globe Trotter</span>
                <span class="tag">MacGyver</span>
              </div>

              <p>I am the lead business development advisor with the Goldman Sachs Wealth Management Team, an advisory practice servicing the needs of ultra-high net worth families for many years, and focus on assets allocation, values-based wealth management planning and portfolio construction. I started my career in Real Estate Finance, focusing on finding lending solutions for real estate investors and home owners. Prior to my career in finance, I played professional basketball in Asia, Europe, South America, The Middle East, as well as here in the states. I earned my B.A. in Political Science with a minor in Business Administration from The University of Oregon. I currently reside in my hometown of Hermosa Beach, where my brother and I run a youth club basketball program.</p>

              <p>Asset Allocation does not ensure a profit or protect against loss in declining markets.</p>
            </div>
          </div>
        </div>

        <div class="container container-card h-100 p-2">
          <div class="row">
            <div class="col-12 text-start">
              <h5 class="text align-self-center me-auto fw-light" style="color: #323232;">
                My Experience
              </h5>
            </div>
          </div>
        </div>

      </section>
    </div>
  </section>
</template>

<script>
import Nav from '@/components/nav/nav.vue';
export default {
  name: 'EnhanceProfile',
  components: {
    Nav
  }
}
</script>

<style scoped src="@/css/bootstrap.min.css"></style>

<style scoped>
.enterprise-setup {
  background-color: #EDEDED;
}
.enterprise-setup h2 {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #000000;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.enterprise-setup h3 {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #142328;
  letter-spacing: 1px;
}
.enterprise-setup h5 {
  padding-bottom: 10px;
  color: #323232;
}
.enterprise-setup .container:first-child > .d-flex {
  border-bottom: 0.5px solid #332C54;
}
.logo {
  max-height: 33px!important;
}
.enterprise-setup img {
  height: 125px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.container-card {
  padding: 20px!important;
  margin-bottom: 30px;
  background-color: #FFFFFF;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
a {
  text-decoration: none;
}
button {
  background-color: #FFFFFF!important;
  border: 1px solid #83C03B;
  box-sizing: border-box;
  border-radius: 30px;
}
button:hover {
  color: #83C03B!important;
  border: 1px solid #83C03B!important;
}
.tag {
  display: inline-block;
  padding: 5px;
  background: rgba(3, 29, 90, 0.2);
  border-radius: 3px;
  margin: 0px 5px;
  color: #1E1E1E;
  font-size: 14px;
}
</style>
